import dynamic from 'next/dynamic'

import { Checkbox, Select, Slot } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { Bucket } from '../../lib/makeswift/experiments'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./ExperimentProvider').then(({ ExperimentProvider }) => ExperimentProvider))
		)
	),
	{
		type: 'experiment-provider',
		label: 'Under Development/Experiment Provider',
		props: {
			a: Slot(),
			b: Slot(),
			enableAmplitudeAllocation: Checkbox({
				label: 'Lock Variants/Enable Allocation',
				defaultValue: false
			}),
			previewBucket: Select({
				label: 'Bucket',
				options: [
					{ value: Bucket.A, label: 'A' },
					{ value: Bucket.B, label: 'B' }
				],
				defaultValue: Bucket.A
			})
		}
	}
)
