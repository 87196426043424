export const SERVER_CACHE_CONTROL = `public, s-maxage=86400, stale-while-revalidate=86400`
export const ROBOTS_CACHE_CONTROL = `public, s-maxage=1800, stale-while-revalidate=1800`

export const Locales = {
	US: 'en-US',
	GB: 'en-GB',
	UK: 'en-UK',
	Australia: 'en-AU',
	France: 'fr-FR',
	Netherlands: 'nl-NL',
	Italy: 'it-IT',
	Spain: 'es-ES',
	Mexico: 'es-MX',
	Germany: 'de-DE',
	Norway: 'nb-NO',
	Denmark: 'da-DK',
	Sweden: 'sv-SE',
	Austria: 'de-AT',
	Singapore: 'en-SG'
} as const

export const APPS_SITEMAP_LOCALES = ['en-US', 'en-GB', 'en-AU']
export const THEMES_SITEMAP_LOCALES = ['en-US', 'en-GB', 'en-AU']
export const GLOSSARY_SITEMAP_LOCALES = ['en-US', 'en-GB', 'en-AU']
export const COMPANY_LEADERS_LOCALES = ['en-US', 'en-GB', 'en-AU']
export const EXCLUDED_RESOURCES_SITEMAP_LOCALES = ['nb-NO', 'sv-SE', 'da-DK', 'de-AT']
export const EXCLUDED_PRESS_SITEMAP_LOCALES = ['de-AT', 'da-DK', 'nb-NO', 'sv-SE', 'en-SG']
export const EXCLUDED_CASE_STUDY_SITEMAP_LOCALES = ['da-DK']
export const DEFAULT_LOCALE: Locale = Locales.US

export const DRIFT_REF = '#drift'
