import dynamic from 'next/dynamic'

import { Slot, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./MegaMenuWrapper').then(({ MegaMenuWrapper }) => MegaMenuWrapper)))
	),
	{
		type: 'mega-menu-wrapper',
		label: `${FolderStructure.UnderDevelopment}/${FolderStructure.MegaMenu}/Mega Menu Wrapper`,
		props: {
			content: Slot(),
			className: Style({ properties: [Style.Width, Style.Margin, Style.Padding] })
		}
	}
)
