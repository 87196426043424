import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import getLocalizedNameFromEnglish from '@/utils/locales/localized-routes/getLocalizedNameFromEnglish';
import { onImageError } from '@/utils/sentry/onImageError';
import { Image } from '@/components/Image';
import { Button } from '../Button/Button';
const VARIANT_STYLES = {
  light: 'text-white',
  dark: 'text-bc-black'
};
type Props = {
  className?: string;
  isContained?: boolean;
  'data-testid'?: string;
  isReversed?: boolean;
  isFeatured?: boolean;
  imageSrc?: {
    url?: string;
    dimensions: {
      width?: number;
      height?: number;
    };
  };
  imageAlt: string;
  imageRatio?: 'none' | 'video' | 'square' | '4/3';
  linkText: string;
  linkURL?: {
    href: string;
    target?: '_self' | '_blank';
  };
  anchorLink?: string;
  heading?: string;
  subTitle?: string;
  title?: string;
  text?: string;
  bgColor?: string;
  variant?: 'light' | 'dark';
  truncateText?: boolean;
  verticalContentAlign?: 'top' | 'between';
};
const IMAGE_RATIO = {
  none: '',
  video: 'aspect-video',
  square: 'aspect-square',
  '4/3': 'aspect-[4/3]'
};
export function Card({
  className,
  isContained = true,
  isFeatured,
  isReversed,
  imageSrc,
  imageAlt,
  linkText,
  linkURL,
  anchorLink,
  heading,
  subTitle,
  title,
  text,
  bgColor,
  'data-testid': testId = 'card-component',
  imageRatio = 'none',
  variant = 'light',
  truncateText = false,
  verticalContentAlign
}: Props) {
  const {
    locale = 'en-US'
  } = useRouter();
  return <div className={clsx(className, VARIANT_STYLES[variant], isFeatured ? 'flex-col sm:flex-row' : 'flex-col', isReversed && 'sm:flex-row-reverse', isContained ? 'self-stretch' : 'gap-8', 'flex w-full')} data-testid={testId} style={{
    background: isContained ? bgColor : undefined
  }} data-sentry-component="Card" data-sentry-source-file="Card.tsx">
			{imageSrc?.url && <Image src={imageSrc.url.startsWith('//') ? `https:${imageSrc.url}` : imageSrc.url} alt={imageAlt} width={imageSrc.dimensions.width} height={imageSrc.dimensions.height} onError={onImageError} className={clsx(isFeatured ? 'w-full aspect-video sm:w-1/2 sm:aspect-auto' : 'w-full', IMAGE_RATIO[imageRatio], 'object-cover')} />}
			<div className={clsx('flex flex-col text-current break-words', isContained && 'px-8 md:px-10 py-12', verticalContentAlign !== 'top' && 'flex-1')}>
				<div className={clsx(verticalContentAlign !== 'top' && 'flex-1')}>
					{heading && <div className="text-eyebrow uppercase mb-1.5">
							{getLocalizedNameFromEnglish({
            locale,
            name: heading,
            capitalized: true
          })}
						</div>}
					{title && <h3 data-testid="card-title" className="text-lg font-medium">
							{title}
						</h3>}
					{subTitle && <p className="text-xs uppercase mt-5 font-medium">{subTitle}</p>}
					{text && <p className={clsx('text-base mt-5', truncateText && 'line-clamp-3')}>{text}</p>}
				</div>
				{anchorLink || linkURL?.href && linkURL?.href !== '#' ? <div>
						<Button variant="subtle" color={{
          light: ('tertiary' as const),
          dark: ('secondary' as const)
        }[variant]} size="large" link={linkURL} anchorLink={anchorLink} showIcon={true} className="mt-7">
							{linkText}
						</Button>
					</div> : null}
			</div>
		</div>;
}